import Vue from 'vue'
import VueRouter, { RawLocation, Route } from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: RawLocation): Promise<Route> {
  return new Promise((resolve, reject) => {
    originalPush.call(this, location, () => {
      // on complete

      resolve(this.currentRoute);
    }, (error) => {
      // on abort

      // only ignore NavigationDuplicated error
      if (error.name === 'NavigationDuplicated' || error.name === 'NavigationCancelled') {
        resolve(this.currentRoute);
      } else {
        reject(error);
      }
    });
  });
};

const routes = [
  {
    path: '/',
  },
  {
    path: '/camera-permission',
    name: 'CameraPermission',
    component: () => import('../views/CameraPermission.vue')
  },

  {
    path: '/location-permission',
    name: 'LocationPermission',
    component: () => import('../views/LocationPermission.vue')
  },

  {
    path: '/on-boarding',
    name: 'OnBoarding',
    component: () => import('../views/OnBoarding.vue')
  },

  {
    path: '/booking/:eventId',
    name: 'Booking',
    component: () => import('../views/Booking.vue')

  },

  {
    path: '/reservation',
    name: 'Reservation',
    component: () => import('../views/Reservation.vue'),
    props: true
    
  },

  // waiting 
  {
    path: '/waiting',
    name: 'Waiting',
    props: true,
    component: () => import('../views/Waiting.vue')
  },

  // quiz
  {
    path: '/quiz',
    name: 'Quiz',
    component: () => import('../views/Quiz.vue'),
    props: true
  },

  // matches
  {
    path: '/matches',
    name: 'Matches',
    component: () => import('../views/Matches.vue')
  },

  // chats
  {
    path: '/chats',
    name: 'Chats',
    component: () => import('../views/Chats.vue')
  },

  // chat
  {
    path: '/chat',
    name: 'Chat',
    component: () => import('../views/Chat.vue')
  },

  {
    path: '/chat/quiz',
    name: 'ChatQuiz',
    component: () => import('../views/ChatQuiz.vue')
  },

  // profile
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    props: true
  },


  // tutorial subroutes
  {
    path: '/tutorial1',
    name: 'Tutorial1',
    component: () => import('../views/Tutorial/1.vue')
  },

  {
    path: '/tutorial2A',
    name: 'Tutorial2A',
    component: () => import('../views/Tutorial/2A.vue')
  },

  {
    path: '/tutorial2B',
    name: 'Tutorial2B',
    component: () => import('../views/Tutorial/2B.vue')
  },

  {
    path: '/tutorial3',
    name: 'Tutorial3',
    component: () => import('../views/Tutorial/3.vue')
  },

  {
    path: '/tutorial4',
    name: 'Tutorial4',
    component: () => import('../views/Tutorial/4.vue')
  },

  {
    path: '/tutorial5',
    name: 'Tutorial5',
    component: () => import('../views/Tutorial/5.vue')
  },

  {
    path: '/tutorial6',
    name: 'Tutorial6',
    component: () => import('../views/Tutorial/6.vue')
  },

  
]

const router = new VueRouter({
  mode: 'abstract',
  base: process.env.BASE_URL,
  routes
})

export default router
