<template>
  <div id="app">
    <PtyLanguageSelector :languages="['it', 'en']" @selected="selectedLanguage = $event" />
    <transition name="slide" mode="out-in">
      <router-view :key="$route.fullPath" />
    </transition>
  </div>
</template>
<script>
const imagesToPreload = [
  require('./assets/images/splash/1.png'),
  require('./assets/images/splash/2.png'),
  require('./assets/images/splash/3.png'),
  require('./assets/images/splash/4.png'),
  require('./assets/images/splash/5.png'),
  require('./assets/images/splash/6.png'),
]

// @ts-ignore
import * as Sentry from '@sentry/vue';
import PtyLanguageSelector from './components/PtyLanguageSelector.vue';
import {chatService, eventService, partecipantService} from '@/rest';
import {GlobalEventEmitter} from './GlobalEventEmitter.js';
export default {
  name: 'App',
  data: () => ({
    chatWs: null,
    chatWsLoading: null,

    feedWs: null,
    feedWsLoading: null,

    avoidReconnection: false,

    preloadedImages: [],
  }),

  components: {
    PtyLanguageSelector
  },

  
  methods: {
    async preloadImages(images){
      // promise all images
      await Promise.all(images.map(async function(image){
        const img = new Image();
        img.src = image;
        await new Promise((resolve) => {
          img.onload = resolve;
          img.onerror = resolve;
        });
        console.log('loaded', image);
      }));
    },

    chatWsConnect(){
      console.log(this.chatWs);
      if(this.chatWs && (this.chatWs.readyState === WebSocket.OPEN || this.chatWs.readyState === WebSocket.CONNECTING)){
        console.log('already connecting/connected');
        return;
      }

      if(this.chatWsLoading){
        this.chatWsLoading.close();
        this.chatWsLoading = null;
      }

      this.chatWsLoading = this.$vs.loading({
        text: this.$t('Chats.connecting')
      });

      this.chatWs = chatService.connectToWebSocket();

      this.chatWs.onerror = (event) => {
        Sentry.captureException('[WS-ERROR] An error occurred in the Chat WebSocket', { extra: { event } });
      },

      this.chatWs.onmessage = (message) => {
        GlobalEventEmitter.$emit('chat-message', message);
      },

      this.chatWs.onopen = () => {
        if(this.chatWsLoading){
          this.chatWsLoading.close();
          this.chatWsLoading = null;
        }
        GlobalEventEmitter.$emit('chat-connected');
      }

      // when disconnects, retry in 5s
      this.chatWs.onclose = (event) => {
        this.chatWs = null;

        if(this.avoidReconnection){
          if(this.chatWsLoading){
            this.chatWsLoading.close();
            this.chatWsLoading = null;
          }
          return;
        }

        Sentry.captureException('[EVT] Chat WS closed', { extra: { event } });

        setTimeout(() => {
          if(this.chatWsLoading){
            this.chatWsLoading.close();
            this.chatWsLoading = null;
          }

          this.chatWsLoading = this.$vs.loading({
            text: this.$t('Chats.reconnecting')
          });

        }, 1000);

        setTimeout(() => {
          this.chatWsConnect();
        }, 3000);
      }
    },

    feedWsConnect(){
      console.log(this.chatWs);
      if(this.feedWs && (this.feedWs.readyState === WebSocket.OPEN || this.feedWs.readyState === WebSocket.CONNECTING)){
        console.log('already connecting/connected');
        return;
      }

      if(this.feedWsLoading){
        this.feedWsLoading.close();
        this.feedWsLoading = null;
      }

      this.feedWsLoading = this.$vs.loading({
        text: this.$t('Chats.connecting')
      });

      this.feedWs = eventService.connectToWebSocket();

      this.feedWs.onerror = (event) => {
        Sentry.captureException('[WS-ERROR] An error occurred in the Feed WebSocket', { extra: { event } });
      },

      this.feedWs.onmessage = (message) => {
        GlobalEventEmitter.$emit('feed-message', message);
        //Sentry.captureMessage('[WS-FEED] ' + message.data);
        this.handleFeedMessage(message);
      },

      this.feedWs.onopen = () => {
        if(this.feedWsLoading){
          this.feedWsLoading.close();
          this.feedWsLoading = null;
        }
        GlobalEventEmitter.$emit('feed-connected');

        this.verifyEventStatus();
      }

      // when disconnects, retry in 5s
      this.feedWs.onclose = (event) => {
        this.feedWs = null;

        if(this.avoidReconnection){
          if(this.feedWsLoading){
            this.feedWsLoading.close();
            this.feedWsLoading = null;
          }
          return;
        }

        Sentry.captureException('[EVT] Feed WS closed', { extra: { event } });
        setTimeout(() => {
          if(this.feedWsLoading){
            this.feedWsLoading.close();
            this.feedWsLoading = null;
          }
          if(!window.location.href.includes('localhost')){
            this.feedWsLoading = this.$vs.loading({
              text: this.$t('Chats.reconnecting')
            });
          }
        }, 1000);

        setTimeout(() => {
          this.feedWsConnect();
        }, 10000);
      }
    },

    wsDisconnect(){
      this.avoidReconnection = true; // prevent it to think it was disconnected by error and retry
      if(this.chatWs){
        this.chatWs.close();
        this.chatWs = null;
      }
      
      if(this.feedWs){
        this.feedWs.close();
        this.feedWs = null;
      }
    },

    chatWsSend(d){
      this.chatWs.send(d);
    },

    wsConnect(){
      this.avoidReconnection = false;
      this.chatWsConnect();
      this.feedWsConnect();
    },

    async handleFeedMessage(message){
      const data = JSON.parse(message.data);
      if(data.type === 'EVENT_CHANGE'){
        if(data.event.status == 'CLOSED'){
          this.$router.push({
            name: 'OnBoarding'
          })
        }
      }
    },

    async verifyEventStatus(){
      try {
        const participant = await partecipantService.detail();
        if(participant.event.status == 'CLOSED'){
          this.$router.push({
            name: 'OnBoarding'
          })
        }
      } catch (error) {
        Sentry.captureException(error);

        // go to onboarding anyway
        this.$router.push({
          name: 'OnBoarding'
        })
      }
    }
  },

  beforeDestroy() {
    GlobalEventEmitter.$off('ws-connect', this.wsConnect);
    GlobalEventEmitter.$off('ws-disconnect', this.wsDisconnect);
    GlobalEventEmitter.$off('ws-send', this.chatWsSend);
  },

  async created() {
    GlobalEventEmitter.$on('ws-connect', this.wsConnect);
    GlobalEventEmitter.$on('ws-disconnect', this.wsDisconnect);
    GlobalEventEmitter.$on('ws-send', this.chatWsSend);

  },

  async mounted(){
    if(window.location.href.includes('b=')){
      const eventId = window.location.href.split('b=')[1].split('&')[0];
      this.$router.push({
        name: 'Booking',
        params: {
          eventId
        }
      });
      return;
    }

    const loading = this.$vs.loading();
    try {
      const participant = await partecipantService.detail();
      if (participant.id != null && participant.event != null) { // if the user is logged in
        if(participant.event.status != 'CLOSED' && participant.event.id != null && participant.event.id != ''){
            // it means the user is partecipating to an event that is not closed and not deleted
            loading.close();
            this.$router.push({ name: 'OnBoarding' });
            return;
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }

    await this.preloadImages(imagesToPreload);
    loading.close();

    this.$router.push({ name: 'Tutorial1'} );
  }
}
</script>
<style>

#app {
  font-family: 'Sofia Pro', sans-serif;
}

#app, body, html {
  margin: 0;
  background: var(--Linear, linear-gradient(12deg, #00A9C0 0.95%, rgba(0, 169, 192, 0.63) 101.3%));
  overflow-y:hidden!important;
  overflow-x:hidden!important;
  height:100%;
}

.vs-notification__content {
  font-family: 'Sofia Pro', sans-serif!important;
}

/* 20px padding X, 10px padding Y */
#app > *:not(.tutorial) {
  padding: 10px 20px!important;
  box-sizing: border-box;
  touch-action: manipulation;
  max-height: 100%;

}

/* fade vue transition */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.3s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

.pty-divider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

.pty-divider hr {
  flex-grow: 1;
  border: 0;
  height: 1px;
  background: #CDCDCD;
}

.pty-divider p {
  color: #CDCDCD;
  text-transform: uppercase;
  font-size: 0.8rem!important;
  font-weight:300!important;
}

.vs-avatar-content.history:after {
  background: rgba(var(--vs-primary))!important;
}

.inbox .vs-avatar-content--size.history:after{
  top:0!important;
}

.inbox .vs-avatar__badge {
    background: transparent;
    border: unset !important;
    left: -54px;
    bottom: -5px;
}
.inbox .vs-avatar__badge img {
    /* png shadow filter */
    /*filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.35)); causes glithces */
    transform: rotate(15deg);
}

.slide-down {
  max-height: 200px;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.slide-down.collapsed {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.vs-loading__load__text {
  font-family: 'Sofia Pro', sans-serif;
  font-size: 1.2rem;
}

body, html {
  overscroll-behavior: none; /* Prevents scroll chaining */
  touch-action: none; /* Disables touch gestures (e.g., panning, zooming) */
}

.vs-notification__content__text p {
  color: white!important;
  font-family: 'Sofia Pro', sans-serif!important;
  font-size: 1rem!important;
}

.vs-notification__content__header h4 {
  font-family: 'Sofia Pro', sans-serif!important;
  font-size: 1.2rem!important;
}
.sticked-bottom {
    position: fixed!important;
    bottom: 20px!important;
    width: calc(100% - 40px)!important;
}
</style>
