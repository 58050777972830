/* eslint-disable */
import apiClient from '../client';
import {
    CreateEventRequest,
    GetEventsRequest,
    DeleteEventRequest,
    GetEventRequest,
    CreateEventResponse,
    GetEventsResponse,
    GetEventResponse,
    GetPublicEventsRequest,
} from '../types';

const eventService = {
    create: async (businessId: string, data: CreateEventRequest): Promise<CreateEventResponse> => {
        const response = await apiClient.post<CreateEventResponse>(`/businesses/${businessId}/events`, data);
        return response.data;
    },

    get: async (businessId: string, params?: GetEventsRequest): Promise<GetEventsResponse> => {
        const response = await apiClient.get<GetEventsResponse>(`/businesses/${businessId}/events`, { params });
        return response.data;
    },

    detail: async (params: GetEventRequest): Promise<GetEventResponse> => {
        const response = await apiClient.get<GetEventResponse>(`/events/${params.id}`);
        return response.data;
    },

    delete: async (params: DeleteEventRequest): Promise<void> => {
        await apiClient.delete(`/businesses/${params.businessId}/events/${params.id}`);
    },

    // get public events
    getPublic: async (params?: GetPublicEventsRequest): Promise<GetEventsResponse> => {
        const response = await apiClient.get<GetEventsResponse>('/events', { params });
        return response.data;
    },

    connectToWebSocket: (): WebSocket => {
        const base = apiClient.defaults.baseURL?.replace('https://', 'wss://').replace('http://', 'ws://');
        const ws = new WebSocket(`${base}/feed`);
        return ws;
    },
};

export default eventService;
