/* eslint-disable */
import apiClient from '../client';
import {
    GetChatsRequest,
    CreateChatRequest,
    GetChatsResponse,
    CreateChatResponse,
} from '../types';

const chatService = {
    get: async (params?: GetChatsRequest): Promise<GetChatsResponse> => {
        const response = await apiClient.get<GetChatsResponse>('/chats', { params });
        return response.data;
    },

    create: async (data: CreateChatRequest): Promise<CreateChatResponse> => {
        const response = await apiClient.post<CreateChatResponse>('/chats', data);
        return response.data;
    },

    connectToWebSocket: (): WebSocket => {
        const base = apiClient.defaults.baseURL?.replace('https://', 'wss://').replace('http://', 'ws://');
        const ws = new WebSocket(`${base}/chats/stream`);
        return ws;
    },

    // get suggestione
    suggest: async (id: string): Promise<any> => {
        const response = await apiClient.post(`/chats/${id}:suggest`);
        return response.data;
    },

    details: async (id: string): Promise<any> => {
        const response = await apiClient.get(`/chats/${id}/messages`);
        return response.data;
    },

    getQuestions: async(data: any): Promise<any> => {
        const response = await apiClient.get<any>(`/chats/${data.chatId}/questions`, { params: data });
        return response.data;
    },

    answerQuestion: async (data: any): Promise<any> => {
        const response = await apiClient.post<any>(`/chats/${data.chatId}/questions/${data.questionId}/answer`, data);
        return response;
    },

    getAnswers: async(data: any): Promise<any> => {
        const response = await apiClient.post<any>(`/chats/${data.chatId}/questions/${data.questionId}/answers`);
        return response.data;
    },

    markAsRead: async (data: any): Promise<any> => {
        const response = await apiClient.post<any>(`/chats/${data.chatId}/messages/${data.offset}:read`);
        return response;
    },

};

export default chatService;
