<template>
    <div class="pty-selector" >
        <div class="pty-selector__content">
            <div class="pty-selector__options" :class="{'not-block': !block, 'half': half}" :style="{gap: gap + 'rem'}">
                <PtyButton v-for="option in options" :key="option.id" :block="block" :light="(iconType == 'svg' || !(selected === option.id || selecteds.includes(option.id)))" shadow @click="select(option)" :loading="animateds.includes(option.id)" :noDisable="true" class="pty-selector__button" :size="size" :class="{'pty-selector__button__selected': selected === option.id || selecteds.includes(option.id)}" :id="option.id.toString()">
                    <div class="pty-selector__option_label pty-selector__option_label_absolute" v-if="option.label">
                        {{ option.label }}
                    </div>
                    <div class="pty-selector__option" :class="{'active': selected === option.id || selecteds.includes(option.id)}">
                        <div class="pty-selector__option__icon" :class="{'stroke': colorizeMode == 'stroke', 'fill': colorizeMode == 'fill', 'pty-selector__option__icon__small': size == 'small'}">
                            <component v-if="iconType == 'svg'" :is="option.icon" />
                            <img v-else-if="iconType == 'png'" :src="option.icon" />
                            <i class="bx bx-sm" :class="option.icon" v-else-if="iconType == 'boxicon'" :style="{color: (selected === option.id || selecteds.includes(option.id)) ? '#ffffff' : undefined}"></i>
                        </div>
                        <div class="pty-selector__option__text">
                            <h2 :style="{color: ((size == 'small' && !textDark)  ? ((selected === option.id || selecteds.includes(option.id)) ? '#ffffff' : '#000000') : undefined), fontWeight: size == 'small' ? '500': undefined}">{{ option.title }}</h2>
                            <p v-if="size != 'small'">{{ option.caption }}</p>
                            <div class="pty-selector__option_label" v-if="option.bottom_label">
                                {{ option.bottom_label }}
                            </div>
                        </div>

                        <div class="pty-selector__option_date" v-if="option.day">
                            <h1>
                                {{ option.day }}
                            </h1>
                            <p>{{ option.month }}</p>
                        </div>

                        <div class="pty-selector__check_icon" v-if="showCheck">
                            <CheckIcon />
                        </div>
                    </div>
                    <div class="pty-selector__option__extra_caption" v-if="option.extra_caption && (selected === option.id || selecteds.includes(option.id))">
                        <p>{{ option.extra_caption }}</p>
                    </div>
                </PtyButton>
            </div>
        </div>
    </div>
</template>

<script>
import CheckIcon from '../assets/images/icons/small-check.svg';
import PtyButton from './PtyButton.vue';
export default {
    name: 'OnBoarding',
    components: {
        PtyButton,
        CheckIcon
    },
    props: {
        options: {
            type: Array, // id, title, caption, icon
            default: () => []
        },

        value: {
            type: [String, Number, Array],
            default: 1
        },

        block: {
            type: Boolean,
            default: true
        },

        colorizeMode: {
            type: String,
            default: 'fill'
        },

        multiple: {
            type: Boolean,
            default: false
        },

        iconType: {
            type: String,
            default: 'svg'
        },

        size: {
            type: String,
            default: 'xl'
        },

        showCheck: {
            type: Boolean,
            default: false
        },

        gap: {
            type: Number,
            default: 1.5
        },

        half: {
            type: Boolean,
            default: false
        },

        textDark: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
       selected: null,
       animateds: [],
       selecteds: []
    }),

    watch: {
        selected() {
            this.$emit('input', this.selected);
        },

        selecteds() {
            this.$emit('input', this.selecteds);
        },

        value() {
            if(this.multiple) {
                this.selecteds = this.value;
            } else {
                this.selected = this.value;
            }
        }
    },

    methods: {
        select(option) {
            if(this.multiple) {
                if(this.selecteds.includes(option.id)) {
                    this.selecteds = this.selecteds.filter((id) => id !== option.id);
                } else {
                    this.selecteds.push(option.id);
                }
            } else {
                this.selected = option.id;
            }

            this.animateds.push(option.id);
            setTimeout(() => {
                this.animateds = this.animateds.filter((id) => id !== option.id);
            }, 2000);
        }
    },

    mounted() {
        // get model trough v-model
        if(this.value){
            if(this.multiple) {
                this.selecteds = this.value;
            } else {
                this.selected = this.value;
            }
        }
    }
}
</script>

<style>
h2 {
    margin: 0;
    color: #000;
    font-size: 1.15625rem;

}

p {
    margin: 0;
    color: #555555;
    font-weight: 400;
    font-size: 0.9375rem;
}
.pty-selector {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
}

.pty-selector__options {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
}

.pty-selector__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    width: 100%;
}

.pty-selector__option {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.pty-selector__option__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    gap: 0.4rem;
    flex-grow: 1;
}

.pty-selector__option__icon {
    border-radius: 100%;
    width: 2.875rem;
    height: 2.875rem;
    min-width: 2.875rem;
    background: rgba(205, 205, 205, 0.30);
    display: flex;
    justify-content: center;
    align-items: center;

}

.pty-selector__option__icon.fill svg > * {
    fill: #CDCDCD!important;
}

.pty-selector__option__icon.stroke svg > * {
    stroke: #CDCDCD!important;
}

.pty-selector__option.active .pty-selector__option__icon {
    background: rgba(var(--vs-primary));
}
.pty-selector__option.active .pty-selector__option__icon.fill svg > * {
    fill: white!important;
}

.pty-selector__option.active .pty-selector__option__icon.stroke svg > * {
    stroke: white!important;
}

.pty-selector__option_date h1 {
    margin: 0;
    font-size: 1.5rem;
    color: #000;
}

.pty-selector__option_date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 0rem;
}

.pty-selector__button .vs-button__content {
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.pty-selector__button .vs-button__content, .pty-selector__button {
    overflow: visible !important;
}

.pty-selector__option__extra_caption {
    width: 100%;
    text-align: left;
}

.pty-selector__option_label {
    background: rgba(var(--vs-primary));
    color: white;
    padding: 0.3rem 0.5rem;
    border-radius: 0.5rem;
    font-family: "Sofia Pro";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    box-shadow: 0px 10px 35px 0px rgba(68, 188, 204, 0.20);
}

.pty-selector__option_label_absolute {
    position: absolute;
    top: -15px;
}

.pty-selector__options.not-block {
    flex-direction: row;
    flex-wrap: wrap;
}

.pty-selector__options.half .vs-button {
    width: 48%;
}

.pty-selector__options.half {
    width: 100%;
    gap: 0!important;
    justify-content: space-between;
}

.pty-selector__option__icon__small img, .pty-selector__option__icon__small {
    height: 1.5rem;
    width: unset;
    min-width: unset;
    background: none!important;
}

.pty-selector__check_icon {
    background: #EEEEEE;
    border-radius: 100%;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.2rem;
    height: 1.2rem;
    min-width: 1.2rem;
}

.pty-selector__check_icon svg > * {
    stroke: #CDCDCD;
}

.pty-selector__button__selected .pty-selector__check_icon svg > * {
    stroke: rgba(var(--vs-primary));
}

.pty-selector__button__selected .pty-selector__check_icon {
    background: #fff;
}
</style>