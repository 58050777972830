import axios, {AxiosInstance} from 'axios';
import VueInstance from '../main';

// @ts-ignore
import * as Sentry from '@sentry/vue';


// Create an Axios instance
const apiClient: AxiosInstance = axios.create({
  baseURL: (<any>window).CONFIGURATION.API_URL,
  timeout: 30000, // Timeout in milliseconds
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: function (status: number) {
    return status >= 200 && status < 500; // default
  }
});


// Response Interceptor for Error Handling
apiClient.interceptors.response.use(
  (response: any) => {
    const status = response.status;
    if (status === 401) {
      // Handle unauthorized access
      (<any>VueInstance)?.$vs?.notification({
          title: 'Errore',
          text: 'Credenziali non valide. Effettua nuovamente il login',
          position: 'top-center',
          color: 'primary', duration: 5000,
      });
      if((<any>VueInstance)?.$route?.name !== 'OnBoarding') {
        (<any>VueInstance)?.$router.push({ name: 'OnBoarding' });
      }
    } else if (status === 403) {
      // Handle forbidden access
      (<any>VueInstance)?.$vs?.notification({
        title: 'Errore',
          text: 'Non disponi dei permessi necessari per accedere a questa risorsa',
          position: 'top-center',
          color: 'primary', duration: 5000,
      });
    }
    return response;
  },
  (error: any) => {
    Sentry.captureException(error);

    // Handle errors globally
    if (error.response) {
      (<any>VueInstance)?.$vs?.notification({
        title: 'Errore ' + status,
          text: 'Si è verificato un errore. Riprova più tardi',
          position: 'top-center',
          color: 'primary', duration: 5000,
      });
    } else if (error.request) {
      // Network error
      (<any>VueInstance)?.$vs?.notification({
        title: 'Errore',
          text: 'Errore di rete. Riprova più tardi',
          position: 'top-center',
          color: 'primary', duration: 5000,
      });
    } else {
      // Other errors
      (<any>VueInstance)?.$vs?.notification({
        title: 'Errore',
          text: 'Si è verificato un errore. Riprova più tardi',
          position: 'top-center',
          color: 'primary', duration: 5000,
      });
    }

    return Promise.reject(error);
  }
);

export default <AxiosInstance>apiClient;